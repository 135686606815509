<template>
  <div>
    <b-card no-body>
      
    </b-card>

    <b-card no-body>
      <b-table
        sticky-header
        :items="installments"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id } }"
            class="font-weight-bold"
          >
            #{{ data.value }}
          </b-link>
        </template>

        <!-- Column: Invoice Status -->
        <template #cell(invoiceStatus)="data">
          <b-avatar
            :id="`invoice-row-${data.item.id}`"
            size="32"
            :variant="`light-${
              resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant
            }`"
          >
            <feather-icon
              :icon="
                resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon
              "
            />
          </b-avatar>
          <b-tooltip :target="`invoice-row-${data.item.id}`" placement="top">
            <p class="mb-0">
              {{ data.item.invoiceStatus }}
            </p>
            <p class="mb-0">Balance: {{ data.item.balance }}</p>
            <p class="mb-0">Due Date: {{ data.item.dueDate }}</p>
          </b-tooltip>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.client.name }}
            </span>
            <small class="text-muted">{{ data.item.client.companyEmail }}</small>
          </b-media>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(created_at)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-send-icon`"
              icon="SendIcon"
              class="cursor-pointer"
              size="16"
            />
            <b-tooltip
              title="Send Invoice"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-send-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1"
              @click="
                $router.push({
                  name: 'apps-invoice-preview',
                  params: { id: data.item.id },
                })
              "
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />

            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item>
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'apps-invoice-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">Duplicate</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ChartjsComponentLineChart from '@/components/chart/ChartjsComponentLineChart'
import chartjsData from './components/chartjsData'
import StatisticCardVertical from './components/CardAnalistPeriod'

import CardAnalyticProductOrders from './components/CardAnalyticProductOrders'

import CustomPaginateTable from '@/views/components/custom/PaginationTable'

import vSelect from "vue-select";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BTable,
    CardAnalyticProductOrders,
    ChartjsComponentLineChart,
    BMedia,
    StatisticCardVertical,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BTooltip,
    CustomPaginateTable,
  },
  setup() {
    const resolveInvoiceStatusVariantAndIcon = status => {
      if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
      if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
      if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
      if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
      if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
      if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
      return { variant: 'secondary', icon: 'XIcon' }
    }

    const resolveClientAvatarVariant = status => {
      if (status === 'Partial Payment') return 'primary'
      if (status === 'Paid') return 'danger'
      if (status === 'Downloaded') return 'secondary'
      if (status === 'Draft') return 'warning'
      if (status === 'Sent') return 'info'
      if (status === 'Past Due') return 'success'
      return 'primary'
    }

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    return {
      statusOptions,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
  data() {
    return {
      submitedFilter: false,
      filters: {
        created: null,
        document: '',
      },
      chartjsData,
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        { key: 'id', label: '#', class: 'text-center' },
        { key: 'invoiceStatus', class: 'text-center' },
        { key: 'client', label: 'Fornecedor' },
        { key: 'total', formatter: val => `$${val}`, class: 'text-center' },
        { key: 'created_at', label: 'Cadastro', class: 'text-center' },
        { key: 'actions', class: 'text-center' },
      ],
      installments: [
        {
          id: 4987,
          created_at: '13 Dec 2019',
          client: {
            address: '7777 Mendez Plains',
            company: 'Hall-Robbins PLC',
            companyEmail: 'don85@johnson.com',
            country: 'USA',
            contact: '(616) 865-4180',
            name: 'Jordan Stevenson',
          },
          service: 'Software Development',
          total: 3428,
          avatar: '',
          invoiceStatus: 'Paid',
          balance: '$724',
          dueDate: '23 Apr 2019',
        },
        {
          id: 4988,
          created_at: '17 Jul 2019',
          client: {
            address: '04033 Wesley Wall Apt. 961',
            company: 'Mccann LLC and Sons',
            companyEmail: 'brenda49@taylor.info',
            country: 'Haiti',
            contact: '(226) 204-8287',
            name: 'Stephanie Burns',
          },
          service: 'UI/UX Design & Development',
          total: 5219,
          avatar: require('@/assets/images/avatars/10-small.png'),
          invoiceStatus: 'Downloaded',
          balance: 0,
          dueDate: '15 Dec 2019',
        },
        {
          id: 4989,
          created_at: '19 Oct 2019',
          client: {
            address: '5345 Robert Squares',
            company: 'Leonard-Garcia and Sons',
            companyEmail: 'smithtiffany@powers.com',
            country: 'Denmark',
            contact: '(955) 676-1076',
            name: 'Tony Herrera',
          },
          service: 'Unlimited Extended License',
          total: 3719,
          avatar: require('@/assets/images/avatars/1-small.png'),
          invoiceStatus: 'Paid',
          balance: 0,
          dueDate: '03 Nov 2019',
        },
        {
          id: 4990,
          created_at: '06 Mar 2020',
          client: {
            address: '19022 Clark Parks Suite 149',
            company: 'Smith, Miller and Henry LLC',
            companyEmail: 'mejiageorge@lee-perez.com',
            country: 'Cambodia',
            contact: '(832) 323-6914',
            name: 'Kevin Patton',
          },
          service: 'Software Development',
          total: 4749,
          avatar: require('@/assets/images/avatars/9-small.png'),
          invoiceStatus: 'Sent',
          balance: 0,
          dueDate: '11 Feb 2020',
        },
      ],
      option: {
        xAxis: [
          {
            type: 'category',
            data: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12', '18/12', '19/12', '20/12'],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            name: 'Point One',
            type: 'bar',
            stack: 'advertising',
            data: [220, 232, 201, 334, 290, 230, 220, 360, 80, 130, 310, 460, 110, 80],
            barMaxWidth: '20%',
            barMinWidth: '10px',
          },
          {
            name: 'Point Two',
            type: 'bar',
            stack: 'advertising',
            data: [220, 232, 201, 234, 190, 130, 210, 163, 105, 120, 220, 102, 150, 130],
            barMaxWidth: '20%',
            barMinWidth: '10px',
          },
          {
            name: 'Point Three',
            type: 'bar',
            stack: 'advertising',
            data: [120, 102, 101, 134, 190, 130, 210, 102, 80, 50, 90, 50, 40, 30],
            barMaxWidth: '20%',
            barMinWidth: '10px',
          },
          {
            name: 'Point Four',
            type: 'bar',
            stack: 'advertising',
            data: [350, 432, 301, 392, 90, 230, 310, 130, 50, 250, 50, 120, 50, 110],
            barMaxWidth: '20%',
            barMinWidth: '10px',
            itemStyle: {
              barBorderRadius: [10, 10, 0, 0],
            },
          },
        ],
      },
    }
  },
  methods: {
    async getData() {
      this.$store
        .dispatch("Order/pageToReceive", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        })
    },
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>