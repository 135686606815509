var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { attrs: { "no-body": "" } }),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.installments,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "head(invoiceStatus)",
                fn: function () {
                  return [
                    _c("feather-icon", {
                      staticClass: "mx-auto",
                      attrs: { icon: "TrendingUpIcon" },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _c(
                      "b-link",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          to: {
                            name: "apps-invoice-preview",
                            params: { id: data.item.id },
                          },
                        },
                      },
                      [_vm._v(" #" + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(invoiceStatus)",
                fn: function (data) {
                  return [
                    _c(
                      "b-avatar",
                      {
                        attrs: {
                          id: "invoice-row-" + data.item.id,
                          size: "32",
                          variant:
                            "light-" +
                            _vm.resolveInvoiceStatusVariantAndIcon(
                              data.item.invoiceStatus
                            ).variant,
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: {
                            icon: _vm.resolveInvoiceStatusVariantAndIcon(
                              data.item.invoiceStatus
                            ).icon,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          target: "invoice-row-" + data.item.id,
                          placement: "top",
                        },
                      },
                      [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(" " + _vm._s(data.item.invoiceStatus) + " "),
                        ]),
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("Balance: " + _vm._s(data.item.balance)),
                        ]),
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("Due Date: " + _vm._s(data.item.dueDate)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(client)",
                fn: function (data) {
                  return [
                    _c("b-media", { attrs: { "vertical-align": "center" } }, [
                      _c(
                        "span",
                        { staticClass: "font-weight-bold d-block text-nowrap" },
                        [_vm._v(" " + _vm._s(data.item.client.name) + " ")]
                      ),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(data.item.client.companyEmail)),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" " + _vm._s(data.value) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-nowrap" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer",
                          attrs: {
                            id: "invoice-row-" + data.item.id + "-send-icon",
                            icon: "SendIcon",
                            size: "16",
                          },
                        }),
                        _c("b-tooltip", {
                          staticClass: "cursor-pointer",
                          attrs: {
                            title: "Send Invoice",
                            target:
                              "invoice-row-" + data.item.id + "-send-icon",
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "mx-1",
                          attrs: {
                            id: "invoice-row-" + data.item.id + "-preview-icon",
                            icon: "EyeIcon",
                            size: "16",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "apps-invoice-preview",
                                params: { id: data.item.id },
                              })
                            },
                          },
                        }),
                        _c("b-tooltip", {
                          attrs: {
                            title: "Preview Invoice",
                            target:
                              "invoice-row-" + data.item.id + "-preview-icon",
                          },
                        }),
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              variant: "link",
                              "toggle-class": "p-0",
                              "no-caret": "",
                              right: _vm.$store.state.appConfig.isRTL,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("feather-icon", {
                                        staticClass: "align-middle text-body",
                                        attrs: {
                                          icon: "MoreVerticalIcon",
                                          size: "16",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "DownloadIcon" },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "align-middle ml-50" },
                                  [_vm._v("Download")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: {
                                  to: {
                                    name: "apps-invoice-edit",
                                    params: { id: data.item.id },
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "EditIcon" },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "align-middle ml-50" },
                                  [_vm._v("Edit")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "TrashIcon" },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "align-middle ml-50" },
                                  [_vm._v("Delete")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "CopyIcon" },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "align-middle ml-50" },
                                  [_vm._v("Duplicate")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.currentPage,
              "per-page": _vm.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }